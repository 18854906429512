import React from "react"
import Layout from "@components/layout";
import Animation from "@components/kaigai-fx/animation.js";

// Import SVG
import topCenterLogo from '@images/logo_mv.svg';
import topmissIcon from '@images/top_icon_img.svg';
import media_img02 from '@images/top_media_img01_2.webp';

// 関数呼び出し
import { SeoContents } from "@components/seo-data.js" // Seo情報設定

const Index = () => {
  const pageBUrl = '/business/';
  const pageBUrl1 = '/business#bus1';
  const pageBUrl2 = '/business#bus2';
  const pageBUrl3 = '/business#bus3';
  const pageFXUrl = '/kaigai-fx/';

  // Seo情報設定
  const pathName = 'index';
  const seoTag = SeoContents({ Path:pathName });

  return (
  <>
    <Layout>
      {seoTag}
      <div className={pathName}> 
        {/* FV */}
        <div className="MV_cont fv_bg">
          <div className="fv_bg">
            <div className="max-w-lg md:max-w-5xl mx-auto md:w-full md:pt-[2%] pt-[7%] flex md:px-0 px-7 black_bg">
              <h1 className="md:max-w-[90px] max-w-[75px] pt-4 pr-2 mb-6"><img alt="オウンドメディア運営・WEB集客&マーケティング会社" src={topCenterLogo} /></h1>
              <p className="text-sm text-white pt-5 copy">PR the information you want to deliver<br></br>to the people you want to deliver it to.</p>
            </div>
          </div>
          <div className="max-w-lg md:max-w-5xl mx-auto md:w-full md:mb-[60px] mb-[30px] md:pb-[5%] pb-[16%] md:flex">
            <p className="lg:pt-[8%] md:pt-[10%] pt-[21%] md:px-0 px-5 md:text-left text-left text-white font-semibold md:text-sm text-[12px] md:ml-4 leading-6 md:px-0 px-7">
              オウンドメディア運営・WEB集客&マーケティングなどの<br/>
              <span className="text-2xl leading-9 block">デジタルマーケティング支援を行なっております</span>
            </p>
          </div>
        </div>

        <div className="xl:px-0 px-5">
          <div className="text-left mb-[30px] max-w-xs md:max-w-5xl mx-auto md:mt-[30px] mt-[30px]"> 
            <h2 className="pt-2 font-semibold tracking-wide text-xl md:text-3xl">Our Business</h2>
          </div>

          <Animation customType='falling-item' loop='loop'>
            <div className="md:flex md:justify-between justify-center max-w-xl mx-auto md:max-w-5xl flex-wrap mb-10">
              <div className="md:mx-0 mx-auto xl:w-[300px] md:w-[230px] w-[320px] xl:f-[300px] md:f-[230px] h-[320px] aspect-square overflow-hidden mb-5">
                <div className="top_thum01 aspect-square">
                  <div className="bg-black/40 aspect-square xl:w-[300px] md:w-[230px] w-[320px]">
                    <a href={pageBUrl1} >
                      <span className="mt-2 text-white p-8 bg-black/20 transition-all duration-300 hover:transform hover:scale-[90%] aspect-square flex items-center justify-center w-[100%] max-w-[320px] text-center">
                        蓄積したノウハウを利用した<br/>自社オウンドメディアの運営
                      </span>
                    </a>
                  </div>
                </div>
              </div>

              <div className="md:mx-0 mx-auto xl:w-[300px] md:w-[230px] w-[320px] xl:f-[300px] md:f-[230px] h-[320px] aspect-square overflow-hidden mb-5">
                <div className="top_thum02 aspect-square">
                  <div className="bg-black/40 aspect-square xl:w-[300px] md:w-[230px] w-[320px]">
                    <a href={pageBUrl2}><span className="mt-2 text-white p-8 bg-black/20 transition-all duration-300 hover:transform hover:scale-[90%] aspect-square flex items-center justify-center w-[100%] max-w-[320px] text-center">
                      効果的なsns利用と<br/>web広告の運用<br/>デジタルマーケティング支援</span></a>
                  </div>
                </div>
              </div>
  
              <div className="md:mx-0 mx-auto xl:w-[300px] md:w-[230px] w-[320px] xl:f-[300px] md:f-[230px] h-[320px] aspect-square overflow-hidden mb-10">
                <div className="top_thum03 aspect-square">
                  <div className="bg-black/40 aspect-square xl:w-[300px] md:w-[230px] w-[320px]">
                    <a href={pageBUrl3} ><span className="mt-2 text-white p-7 bg-black/20 transition-all duration-300 hover:transform hover:scale-[90%] aspect-square flex items-center justify-center w-[100%] max-w-[320px] text-center">
                      クライアントの要望と<br></br>利用者のニーズに応える<br></br>トレンドをおさえたWeb制作</span></a>
                  </div>
                  </div>
                </div>
              </div>
            </Animation>


      

         
        </div>
   
        <div className="CV_cont xl:px-0 px-5">
          <div className="text-left mb-[30px] max-w-xs md:max-w-5xl mx-auto"> 
            <h2 className="md:pt-[80px] pt-[60px] font-semibold tracking-wide text-2xl md:text-3xl text-white">Mission</h2>
          </div>
          <Animation customType='falling-item' loop='loop'>
          <div className="md:flex max-w-xs md:max-w-5xl mx-auto justify-start">
            <div className="md:mt-0 mt-[30px] md:w-[65%]">
            <h3 className="text-[20px] mt-2 text-white font-semibold">利益が循環する社会作りのために</h3>
              <p className="md:mt-5 mt-5 text-[17px] text-white mb-5">届けたい人に、届けたい情報を<br/>快適な環境で利用してもらうために<br/>
            私たちはクライアントの期待以上の結果にコミットすることを目指して<br/>自社のプロジェクトで得られた最新のノウハウを還元します。</p>
            </div>

            <div className="mx-auto md:ml-2 md:max-w-[180px] max-w-[120px] md:mb-5 mb-10"><img src={topmissIcon} alt=""/></div>
          </div>
       

          {/* すべてを見る */}
          <div className="pb-14 md:pb-[80px] max-w-xs md:max-w-5xl mx-auto">
            <a href={pageBUrl} className="block max-w-[200px] text-center text-xs text-white font-bold py-5 px-5 my-2 main_btn">VIEW MORE　＞</a>
          </div>
          </Animation>
        </div>

        <div className="xl:px-0 px-5">
          <div className="text-left mb-[30px] max-w-xs md:max-w-5xl mx-auto"> 
            <h2 className="md:pt-[80px] pt-[60px] font-semibold tracking-wide text-2xl md:text-3xl">Media</h2>
          </div>
          <Animation customType='falling-item' loop='loop'>
          <div className="md:flex max-w-xs md:max-w-5xl mx-auto">
            <div className="max-w-[200px] md:mx-0 mx-auto">
              <a href={pageFXUrl} aria-label="海外FX200"><img src={media_img02} alt="" width="382" height="212"/></a>
            </div>
            <div className="md:pl-5 md:mt-0 mt-[30px] md:w-[65%]">
              <h3 className="text-[14px] font-semibold leading-6">海外FX200</h3>
              <p className="md:mt-2 mt-2 font-semibold text-lg text-[#04183B]">海外FX業者の比較やブローカー別に特徴をまとめた 比較サイトです。</p>
              <p className="mt-2">これからFXを始めたいと思っているビギナーの方からすでに取引を行っている経験者のために、各種お役立ち情報を更新してまいります。</p>
              <p className="mt-5 text-xs mb-20 text-[#07306A] underline focus:no-underline"><a href={pageFXUrl}>ページはこちら</a></p>
            </div>
          </div>
          </Animation>
        </div>

      </div>
    </Layout> 
  </>
  )
}
export default Index

